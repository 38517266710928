<template>
  <page :data="pageData" :is-child="false">
    <template #cmsleftcomponents>
      <form @submit.prevent="submit">
        <b-field>
          <b-input v-model="nameAccount" placeholder="Name Account" maxlength="40" required></b-input>
        </b-field>
        <b-field>
          <b-input v-model="Account" placeholder="Account" maxlength="40" required></b-input>
        </b-field>
        <b-field>
          <b-input v-model="accountNo" placeholder="Account No" type="number" maxlength="40" min="0" required></b-input>
        </b-field>
        <div class="p-4 border border-dashed border-gray-500 cursor-pointer rounded-md mt-8 mb-8 relative">
          <div class="flex justify-center">
            <div class="my-auto font-bold">{{ fileName ? fileName : 'Upload File'}}</div>
          </div>
          <input
            type="file"
            @change="uploadFile($event)"
            style="opacity: 0; top: 28%; left: 0"
            class="absolute w-full h-full"
          />
        </div>
        <div>
          <b-button type="is-primary" class="w-full" native-type="submit"
            >Save</b-button
          >
        </div>
      </form>
    </template>
  </page>
</template>

<script>
import Page from '@/components/vaddress/cmspages/Page'
const pageData = {
  config: {
    Header: {
      Title: 'Top Up Account'
    }
  },
  cmsLeft: {
    Data: []
  }
}
export default {
  components: { Page },
  data () {
    return {
      nameAccount: '',
      Account: '',

      accountNo: '',
      User: '',
      userName: '',
      fileName: '',
      file: ''
    }
  },
  methods: {
    uploadFile (e) {
        this.file = e.target.files[0]
        this.fileName = e.target.files[0].name
    },
    async submit () {
      const loading = this.$buefy.loading.open()
      try {
        const fd = new FormData()
        fd.append('NameAccount', this.nameAccount)
        fd.append('Account', this.Account)
        fd.append('AccountNo[]', this.accountNo)
        fd.append('file', this.file)
        const res = await this.$baseApi.post('/user/digitalwallet/addaccount?DigitalWallet=5b8f517d-517d-43b2-8372-a275573eddad ', fd)
        this.$buefy.snackbar.open({
          message: 'Successfully Add Account',
          type: 'is-success',
          position: 'is-bottom',
          duration: 800
        })
        const webcontent = this.$store.state.webcontent
        const topupAccount = webcontent && webcontent.response['/user/dashboard'].Topup.Account
        topupAccount && topupAccount.Source.push(res)
        this.$router.back()
      } catch (err) {
        loading.close()
        console.log(err.message)
        return this.$buefy.snackbar.open({
          message: err.message,
          type: 'is-danger',
          position: 'is-bottom',
          duration: 800
        })
      } finally {
        loading.close()
      }
    }
  },
  computed: {
    pageData () {
      return pageData
    }
  }
}

</script>

<style>
.select select {
  width: 100vh;
}
</style>
